import styled from "styled-components";
import Flex from "app/components/Flex";
import { H3, P2 } from "app/components/Typography";

const DaysLeft = styled(P2)`
  color: ${({ theme: { colors } }) => colors.gold};
`;

const ActiveChallenge = styled(H3)`
  color: ${({ theme: { colors } }) => colors.bulbaGreen};
`;

const Gradient = styled(Flex)`
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(
    transparent 30%,
    ${({ theme }) => theme.colors.monochrome[8]} 100%
  );
`;

const Wrapper = styled(Flex)`
  border: ${({ theme: { colors }, isActive }) =>
    isActive ? `10px solid ${colors.bulbaGreen}` : "none"};
`;

export { DaysLeft, ActiveChallenge, Gradient, Wrapper };
