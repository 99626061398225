import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import { useGetChallengeById } from "hooks/Challenges";
import Flex from "app/components/Flex";
import { VerticalBar } from "app/components/VerticalBar";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import { H2, P1, P2 } from "app/components/Typography";
import { contentPreviewClass } from "services/typewriter/segment";
import { ANALYTICS_LOCATION, ANALYTICS_MODULE } from "constants/index";
import { useCanUserTakeClass } from "hooks/Classes";
import { DaysLeft, ActiveChallenge, Gradient, Wrapper } from "./styles";

export default () => {
  const { id } = useParams();
  const location = useLocation();
  const [previewProgress, setPreviewProgress] = useState(0);
  const previewProgressRef = useRef();
  const { canUserTakeClass } = useCanUserTakeClass({ classId: id });

  const [
    {
      title,
      forClass: {
        id: classId,
        title: classTitle,
        isSaved,
        type,
        categories,
        level,
        style,
        instructor: { name: instructorName } = {},
        preview_url,
      },
      submissionsCount,
      isActive,
      daysLeft,
    },
  ] = useGetChallengeById({
    variables: { id },
  });

  useEffect(() => {
    previewProgressRef.current = previewProgress;
  }, [previewProgress]);

  useEffect(() => {
    return () => {
      contentPreviewClass({
        title: classTitle,
        class_id: classId,
        type,
        saved: isSaved,
        style,
        duration: previewProgressRef.current,
        level,
        path: location.pathname,
        categories,
        instructor: instructorName,
        selected_from: ANALYTICS_MODULE.challenge_detail,
        content_locked: !canUserTakeClass,
        location: ANALYTICS_LOCATION.community,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textShadow = "1px 1px #000";
  const absoluteTextLocation = { _: "18px", sm: "38px" };

  const daysLeftText = `day${daysLeft > 1 ? "s" : ""} left!`;

  return (
    <Wrapper mb={4} position="relative" width="100%" isActive={isActive}>
      <Flex width="100%">
        <ResponsiveVideoPlayer
          objectFit="cover"
          playsinline
          playing
          muted
          loop
          showLoader
          onProgress={videoProgress => {
            setPreviewProgress(Math.floor(videoProgress.playedSeconds));
          }}
          url={preview_url}
        />
        <Gradient />
      </Flex>
      <Flex
        position="absolute"
        top={absoluteTextLocation}
        left={absoluteTextLocation}
        flexDirection="column"
        color="white"
      >
        <H2
          mb="14px"
          textShadow={textShadow}
          fontSize={{ _: "15px", sm: "25px" }}
        >
          Join the {title}
        </H2>
        <Flex ml={1}>
          <P2
            textShadow={textShadow}
            fontSize={{ _: "10px", sm: "12px" }}
            fontWeight="500"
          >
            <b>{submissionsCount}</b> SUBMISSIONS&nbsp;
          </P2>
          {isActive && (
            <>
              <VerticalBar />
              <DaysLeft textShadow={textShadow} fontWeight="600">
                &nbsp;{daysLeft} {daysLeftText}
              </DaysLeft>
            </>
          )}
        </Flex>
      </Flex>
      <Flex
        position="absolute"
        bottom={absoluteTextLocation}
        left={absoluteTextLocation}
        flexDirection="column"
        color="white"
      >
        {isActive && (
          <>
            <ActiveChallenge
              mb={2}
              textShadow={textShadow}
              fontSize={{ _: "12px", sm: "18px" }}
            >
              Active Challenge
            </ActiveChallenge>
            <P1 textShadow={textShadow} fontSize={{ _: "12px", sm: "18px" }}>
              {daysLeft} {daysLeftText}
            </P1>
          </>
        )}
      </Flex>
    </Wrapper>
  );
};
